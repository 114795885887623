import React from 'react';

const NavOutsourcing = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 20C14.5899 20 17.5 17.0899 17.5 13.5C17.5 9.91015 14.5899 7 11 7C7.41015 7 4.5 9.91015 4.5 13.5C4.5 17.0899 7.41015 20 11 20Z"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4258 7.24215C20.3198 6.99026 21.2574 6.93287 22.1755 7.07386C23.0936 7.21486 23.9708 7.55095 24.748 8.0595C25.5252 8.56805 26.1845 9.23726 26.6813 10.022C27.1781 10.8068 27.501 11.689 27.6282 12.6091C27.7554 13.5291 27.684 14.4658 27.4187 15.3559C27.1534 16.2461 26.7005 17.069 26.0903 17.7694C25.4802 18.4697 24.7271 19.0312 23.8817 19.416C23.0363 19.8008 22.1183 19.9999 21.1895 20"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 24.6746C3.01517 23.2306 4.36287 22.052 5.92931 21.2384C7.49576 20.4248 9.23497 20.0001 11.0001 20C12.7652 19.9999 14.5045 20.4246 16.071 21.238C17.6375 22.0515 18.9853 23.23 20.0006 24.6739"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1895 20C22.9548 19.9987 24.6944 20.4228 26.261 21.2364C27.8276 22.05 29.1752 23.2291 30.1895 24.6739"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NavOutsourcing;
